import { type Config } from 'tailwindcss'

export const extendedTheme = {
	colors: {
		lapis: 'rgb(var(--lapis))',
		'hover-lapis': 'rgb(var(--hover-lapis))',
		'light-biege': 'rgb(var(--light-biege))',
		biege: 'rgb(var(--biege))',
		'dark-biege': 'rgb(var(--dark-biege))',
		'jet-black': 'rgb(var(--jet-black))',
		white: 'rgb(var(--white))',
		'disabled-grey': 'rgb(var(--disabled-grey))',
		'light-grey': 'rgb(var(--light-grey))',
		grey: 'rgb(var(--grey))',
		coral: 'rgb(var(--coral))',
		red: 'rgb(var(--red))',
		black: 'rgb(var(--black))',
		green: 'rgb(var(--green))',
		lavender: 'rgb(var(--lavender))',
		'hover-lavender': 'rgb(var(--hover-lavender))',
		midnight: 'rgb(var(--midnight))',
		charcoal: 'rgb(var(--charcoal))',
		ash: 'rgb(var(--ash))',
		elysium: 'rgb(var(--elysium))',
		smoky: 'rgb(var(--smoky))',
		rose: 'rgb(var(--rose))',
		sage: 'rgb(var(--sage))',
		border: 'hsl(var(--border))',
		input: {
			DEFAULT: 'hsl(var(--input))',
			invalid: 'hsl(var(--input-invalid))',
		},
		ring: {
			DEFAULT: 'hsl(var(--ring))',
			invalid: 'hsl(var(--foreground-destructive))',
		},
		background: 'hsl(var(--background))',
		foreground: {
			DEFAULT: 'hsl(var(--foreground))',
			destructive: 'hsl(var(--foreground-destructive))',
		},
		primary: {
			DEFAULT: 'hsl(var(--primary))',
			foreground: 'hsl(var(--primary-foreground))',
		},
		secondary: {
			DEFAULT: 'hsl(var(--secondary))',
			foreground: 'hsl(var(--secondary-foreground))',
		},
		destructive: {
			DEFAULT: 'hsl(var(--destructive))',
			foreground: 'hsl(var(--destructive-foreground))',
		},
		muted: {
			DEFAULT: 'hsl(var(--muted))',
			foreground: 'hsl(var(--muted-foreground))',
		},
		accent: {
			DEFAULT: 'hsl(var(--accent))',
			foreground: 'hsl(var(--accent-foreground))',
		},
		popover: {
			DEFAULT: 'hsl(var(--popover))',
			foreground: 'hsl(var(--popover-foreground))',
		},
		card: {
			DEFAULT: 'hsl(var(--card))',
			foreground: 'hsl(var(--card-foreground))',
		},
	},
	borderRadius: {
		lg: 'var(--radius)',
		md: 'calc(var(--radius) - 2px)',
		sm: 'calc(var(--radius) - 4px)',
	},
	fontSize: {
		// 1rem = 16px
		/** 80px size / 84px high / bold */
		mega: ['5rem', { lineHeight: '5.25rem', fontWeight: '700' }],
		/** 56px size / 62px high / bold */
		h1: ['3.5rem', { lineHeight: '3.875rem', fontWeight: '700' }],
		/** 40px size / 48px high / bold */
		h2: ['2.5rem', { lineHeight: '3rem', fontWeight: '700' }],
		/** 32px size / 36px high / bold */
		h3: ['2rem', { lineHeight: '2.25rem', fontWeight: '700' }],
		/** 28px size / 36px high / bold */
		h4: ['1.75rem', { lineHeight: '2.25rem', fontWeight: '700' }],
		/** 24px size / 32px high / bold */
		h5: ['1.5rem', { lineHeight: '2rem', fontWeight: '700' }],
		/** 16px size / 20px high / bold */
		h6: ['1rem', { lineHeight: '1.25rem', fontWeight: '700' }],

		/** 32px size / 36px high / normal */
		'body-2xl': ['2rem', { lineHeight: '2.25rem' }],
		/** 28px size / 36px high / normal */
		'body-xl': ['1.75rem', { lineHeight: '2.25rem' }],
		/** 24px size / 32px high / normal */
		'body-lg': ['1.5rem', { lineHeight: '2rem' }],
		/** 20px size / 28px high / normal */
		'body-md': ['1.25rem', { lineHeight: '1.75rem' }],
		/** 16px size / 20px high / normal */
		'body-sm': ['1rem', { lineHeight: '1.25rem' }],
		/** 14px size / 18px high / normal */
		'body-xs': ['0.875rem', { lineHeight: '1.125rem' }],
		/** 12px size / 16px high / normal */
		'body-2xs': ['0.75rem', { lineHeight: '1rem' }],

		/** 18px size / 24px high / semibold */
		caption: ['1.125rem', { lineHeight: '1.5rem', fontWeight: '600' }],
		/** 12px size / 16px high / bold */
		button: ['0.75rem', { lineHeight: '1rem', fontWeight: '700' }],
	},
	keyframes: {
		'accordion-down': {
			from: { height: '0' },
			to: { height: 'var(--radix-accordion-content-height)' },
		},
		'accordion-up': {
			from: { height: 'var(--radix-accordion-content-height)' },
			to: { height: '0' },
		},
		'menu-open': {
			from: { transform: 'translateX(0)' },
			to: { transform: 'translateX(-100%)' },
		},
		'menu-close': {
			from: { transform: 'translateX(0)' },
			to: { transform: 'translateX(-100%)' },
		},
		loader: {
			to: {
				opacity: '0.1',
				transform: 'translate3d(0, -1rem, 0)',
			},
		},
	},
	animation: {
		'accordion-down': 'accordion-down 0.2s ease-out',
		'accordion-up': 'accordion-up 0.2s ease-out',
		'menu-open': 'menu-open 0.2s ease-out',
		'menu-close': 'menu-close 0.2s ease-out',
		loader: 'loader 0.6s infinite alternate',
	},
} satisfies Config['theme']
